import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Trendy",
  sku: "Bk50",
  availability: "In stock",
  title: "OCSO Solid Men's Track Pant BK50",
  mrpPrice: "1499",
  sellPrice: "799",
  disc:
    "OCSO track pant with Stay dry during training sessions wearing this pair of black and grey track pants by ocso. these pants feature Swift Dri technology for moisture management",
};
export default () => {
  return <ProdLayout data={data} />;
};
